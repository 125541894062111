<template>
  <div class="bt-anggota pa-5">
    <h6
      class="text-h5 font-weight-bold orange--text text--lighten-1 d-flex align-center"
    >
      <div
        class="rounded-pill orange lighten-1 me-2 px-2 d-flex align-center justify-center py-2 elevation-3"
      >
        <v-icon small color="white">mdi-bell</v-icon>
      </div>
      Anggota
    </h6>
    <Rembug
      class="mt-5"
      target="transaksi/dashboard"
      :list="list"
      @refreshAnggota="getAnggota"
    />
    <v-snackbar v-model="alert.show" :timeout="3000">
      {{ alert.msg }}
    </v-snackbar>
  </div>
</template>
<script>
import helper from "@/utils/helper"
import { mapGetters, mapActions } from "vuex";
import services from "@/services";
import Rembug from "../components/Rembug.vue";
export default {
  name: "Anggota",
  components: {
    Rembug,
  },
  data() {
    return {
      list: {
        anggota: [],
        rembug: null,
        loading: false,
        total_setoran: 0,
        total_penarikan: 0
      },
      alert: {
        show: false,
        msg: "",
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...helper,
    async getAnggota(cm_code) {
      if (!cm_code) {
        cm_code = this.$route.params.cm_code;
      } else {
        this.$router.push(`/anggota/${cm_code}`);
      }
      let payload = new FormData();
      payload.append("cm_code", cm_code);
      payload.append("today", this.today())
      this.list.rembug = Number(cm_code);
      this.list.anggota = [];
      this.list.loading = true;
      try {
        let req = await services.infoMember(payload, this.user.token);
        if (req.status === 200) {
          this.list.total_penarikan = 0
          this.list.total_setoran = 0
          this.list.anggota = req.data.data;
          req.data.data.map((item) => {
            this.list.total_penarikan = Number(this.list.total_penarikan) + Number(item.total_penarikan)
            this.list.total_setoran = Number(this.list.total_setoran) + Number(item.total_penerimaan)
          })
        } else {
          this.alert = {
            show: true,
            msg: data.message,
          };
        }
        this.list.loading = false;
      } catch (error) {
        this.alert = {
          show: true,
          msg: error,
        };
        this.list.loading = false;
      }
    },
  },
  mounted() {
    this.getAnggota(false);
  },
};
</script>
